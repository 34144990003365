// Step 1: Import your component
import * as React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { StaticImage } from "gatsby-plugin-image";
import LayoutFlex from "../components/LayoutFLex";
import Figure from "../components/Figure";
import Container from "../components/Container";


const pageStyles = {
  color: "#232129",
  fontFamily: "Open Sans Condensed",
};

// Step 2: Define your component
const TherapyPage = () => {
  return (
    <main style={pageStyles}>
      <title>Kunsttherapie</title>
      <Header />

      <LayoutFlex style={{
            padding: "2em",
            width: "50%",
          }}>
        <div className="headdings">
          <h1>Kunsttherapie</h1>
        </div>
        <StaticImage 
        alt="Kunst therapie"
        src={"../images/boje.jpg"}
         placeholder="blurred"
         layout="fixed"
         width={800}
         height={400}
        />
      </LayoutFlex>
      <Figure>
        <blockquote>
        <cite>„Die Kunst ist eine Vermittlerin des Unaussprechlichen.“</cite>
       </blockquote>
        <figcaption>J.W. von Goethe</figcaption>
      </Figure>
      <Container>
      <h3>Bilder sagen mehr als tausend Worte</h3>

    <p>Kunsttherapie hat das Ziel, durch den kreativen Prozess, sch&ouml;pferische Kr&auml;fte zu erwecken und den Zugang zur eigenen Lebendigkeit zu finden. Der Gestaltungsprozess erm&ouml;glicht innere und &auml;u&szlig;ere Bilder auszudr&uuml;cken und eigene kreative F&auml;higkeiten zu entfalten.</p>

        <h3>Über Kunsttherapie:</h3>
        <div>
          <p>
            Die phronetische® Kunsttherapie wurde in den 80er Jahren von Irmgard
            M. Starke entwickelt. Sie beruht auf einer bewertungsfreien und
            achtsamen Haltung sich selbst und der Welt gegenüber. Phronetik® ist
            ein Denk- und Handlungsmodell und führt zu tiefer Einsicht und
            Erkenntnis in die Gesetze und Muster des Lebens. Im Hier und Jetzt
            die Einzigartigkeit eines jeden Lebewesens frei entfalten zu können.
          </p>
          <p>
            In der Kunsttherapie geht es nicht um Kunst oder um „schönes“ Malen,
            sondern um den Entstehungsprozess und die fertige Gestaltung.
          </p>
        </div>
        <div className="two lists">
          <div>
            <h4>Anwendungsbereiche:</h4>
            <ul>
              <li>persönliche Entfaltung und Selbsterfahrung </li>
              <li>Stressbewältigung/Burn out </li>
              <li>persönliche Krisen </li>
              <li>Angst/Panikzustände </li>
              <li>Bewältigung von traumatischen Erlebnissen </li>
              <li>Verlust und Trauer </li>
            </ul>
          </div>
          <div>
          <h4>Die Kunsttherapie hat das Ziel:</h4>
          <ul>
            <li>schöpferische Kräfte zu erwecken </li>
            <li>neue Sichtweisen zu ermöglichen </li>
            <li>neue Lebensmöglichkeiten zu schaffen </li>
            <li>das eigene Leben authentisch zu gestalten </li>
            <li>die Herausforderungen des Lebens neu und kreativ zu begegnen </li>
          </ul>
          </div> 
      </div>      
      </Container>
      <Footer />
    </main>
  );
};

// Step 3: Export your component
export default TherapyPage;
